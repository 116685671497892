import {FC, ReactNode} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {setFontSize, Colors} from '@/assets/styles';

type Props = {
  children: ReactNode;
  style?: SerializedStyles;
};

const H2: FC<Props> = props => (
  <h1 css={[title, props.style]}>{props.children}</h1>
);

export default H2;

const title = css`
  ${setFontSize(36)};
  color: ${Colors.Text.Default};
  line-height: 125%;
  font-weight: 600;
  font-style: normal;
`;
