import {FC} from 'react';
import {css} from '@emotion/react';

type Props = {
  color: string;
  size: number;
};

const Logo: FC<Props> = props => (
  <h3 css={[logo, {color: props.color, fontSize: props.size}]}>Tena Life</h3>
);

export default Logo;

const logo = css`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  line-height: 145%;
`;
