import {FC, ReactNode} from 'react';
import {css, SerializedStyles} from '@emotion/react';

type Props = {
  children: ReactNode;
  style?: SerializedStyles;
  row?: boolean;
};

const Box: FC<Props> = props => {
  const direction = props.row ? 'row' : 'column';
  return (
    <div css={[container, props.style, {flexDirection: direction}]}>
      {props.children}
    </div>
  );
};

export default Box;

const container = css`
  display: flex;
  flex-direction: column;
`;
