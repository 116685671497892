import {FC} from 'react';
import {css} from '@emotion/react';
import {setFontSize, Colors, mq} from '@/assets/styles';
import Logo from '../atoms/Logo';
import {Section, Box} from '../atoms/layout';
import {AngleRightIcon} from '@/assets/icons';

const Footer: FC = () => {
  return (
    <Section style={footer} containerStyle={background}>
      <Logo color={Colors.Text.White} size={36} />
      <Box style={lingWrapper}>
        <Box row style={linkItem}>
          <a css={linkText} href="https://fml-hd.jp/contact.html">
            お問合せ
          </a>
          <img src={AngleRightIcon} alt="" />
        </Box>
        <Box row style={linkItem}>
          <a
            css={linkText}
            href="https://policy.tenafml.com/"
            target="_blank"
            rel="noopener noreferrer">
            プライバシーポリシー
          </a>
          <img src={AngleRightIcon} alt="" />
        </Box>
      </Box>
      <p css={copyright}>Copyright © 2022 TENALIFE. All right reserved.</p>
    </Section>
  );
};

export default Footer;

const background = css`
  background: linear-gradient(315deg, #60cddd 0%, #6bd5cf 36.04%, #9befa4 100%);
`;

const footer = css`
  row-gap: 24px;
  ${mq['sp']} {
    row-gap: 40px;
  }
`;

const copyright = css`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  color: ${Colors.Text.White};
  ${setFontSize(14)};
`;

const lingWrapper = css`
  row-gap: 16px;
  ${mq['sp']} {
    column-gap: 32px;
    flex-direction: row;
  }
`;

const linkItem = css`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const linkText = css`
  font-weight: 600;
  line-height: 150%;
  color: ${Colors.Text.White};
  ${setFontSize(16)};
  text-decoration: none;
`;
