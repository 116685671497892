import {FC, ReactNode} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {setFontSize, Colors, mq} from '@/assets/styles';

type Props = {
  children: ReactNode;
  style?: SerializedStyles;
  color?: string;
};

const Text: FC<Props> = props => (
  <p css={[text, props.style, {color: props.color}]}>{props.children}</p>
);

export default Text;

const text = css`
  ${setFontSize(14)};
  line-height: 136%;
  color: ${Colors.Text.Default};
  font-weight: 500;
  font-style: normal;
  ${mq['sp']} {
    ${setFontSize(16)};
    line-height: 125%;
  }
`;
