export const setFontSize = (size: number) => {
  return `font-size: calc( ${size} / ${16} * 1rem )`;
};

export const Colors = {
  Text: {
    Default: '#000000',
    Dark: '#767478',
    White: '#FFFFFF',
  },
  Button: {
    Primary: '#5ECDA7',
  },
};

type Breakpoints = {
  id: 'small' | 'tablet' | 'sp';
  breakpoint: number;
};

type MediaQuery = {
  [key in Breakpoints['id']]: number;
};

const BREAK_POINTS: Breakpoints[] = [
  {
    id: 'small',
    breakpoint: 1280,
  },
  {
    id: 'tablet',
    breakpoint: 768,
  },
  {
    id: 'sp',
    breakpoint: 540,
  },
];

export const mq = BREAK_POINTS.reduce((acc, {id, breakpoint}) => {
  return {
    ...acc,
    [id]: `@media (min-width: ${breakpoint}px)`,
  };
}, {} as MediaQuery);
