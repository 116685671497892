import {FC, ReactNode} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {mq} from '@/assets/styles';

type Props = {
  children: ReactNode;
  containerStyle?: SerializedStyles;
  style?: SerializedStyles;
};

const Section: FC<Props> = props => (
  <section css={[container, props.containerStyle]}>
    <div css={[content, props.style]}>{props.children}</div>
  </section>
);

export default Section;

const container = css`
  padding: 48px 0;
  ${mq['sp']} {
    padding: 80px 0;
  }
`;

const content = css`
  display: flex;
  flex-direction: column;
  margin: 0 24px;
  ${mq['small']} {
    margin: 0 auto;
    max-width: 1200px;
  }
`;
