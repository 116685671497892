import {FC, ReactNode} from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {setFontSize, Colors, mq} from '@/assets/styles';

type Props = {
  children: ReactNode;
  style?: SerializedStyles;
};

const H1: FC<Props> = props => (
  <h1 css={[title, props.style]}>{props.children}</h1>
);

export default H1;

const title = css`
  font-size: 36px;
  color: ${Colors.Text.Default};
  line-height: 125%;
  font-weight: 600;
  font-style: normal;
  ${mq['sp']} {
    ${setFontSize(72)};
  }
`;
