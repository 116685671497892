import emotionReset from 'emotion-reset';
import {Global, css} from '@emotion/react';
import Top from './pages/Top';
import Footer from './components/common/Footer';

function App() {
  return (
    <>
      <Global styles={globalStyle} />
      <main>
        <Top />
      </main>
      <Footer />
    </>
  );
}

export default App;

const globalStyle = css`
  ${emotionReset}
  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
`;
