import {FC} from 'react';
import {css} from '@emotion/react';
import {setFontSize, Colors, mq} from '@/assets/styles';
import {FvImage, FeatureImage} from '@/assets/images';
import {Text, H1} from '@/components/atoms/text';
import Logo from '@/components/atoms/Logo';
import {Section, Box} from '@/components/atoms/layout';
import {AngleRightIcon} from '@/assets/icons';
import FeatureItem from '@/components/features/Item';

const Top: FC = () => {
  const FEATURES = [
    {
      title: 'レベルシステム',
      body: '最初記録するのは1項目だけ。記録を続けるとレベルが上がり、記録できる項目が増えていくことで、負荷を調整しながら進めることができます。',
    },
    {
      title: '特定保険指導対応',
      body: '特定保険指導に対応しています。',
    },
    {
      title: 'グループ機能',
      body: 'チャットでのやりとりやグループで協力してポイントを獲得することで、仲間と共に励まし合いながら楽しく利用できます。',
    },
    {
      title: '検診データ管理',
      body: 'あなたの検診データを管理できます。改善状況を確認することができます。',
    },
  ];

  return (
    <>
      <section css={fv}>
        <div>
          <Logo color="#00C300" size={43} />
          <Box row style={borderWrap}>
            <Text style={inspection}>検証実施中</Text>
            <div css={border} />
          </Box>
          <Box>
            <div>
              <H1>いつのまにか</H1>
              <H1>健康的な習慣が</H1>
              <H1>ついている</H1>
            </div>
            <Text style={fvText}>
              Tenalifeはグループで楽しく利用することで、健康的な習慣付けがいつのまにかできているアプリケーションです。
            </Text>
          </Box>
          <a
            css={fvButton}
            href="https://fml-hd.jp/contact.html"
            target="_blank"
            rel="noopener noreferrer">
            <span css={buttonText}>お問い合わせ</span>
            <img src={AngleRightIcon} alt="" />
          </a>
        </div>
        <Box>
          <img css={fvImage} src={FvImage} alt="" />
        </Box>
      </section>
      <Section containerStyle={background}>
        <Box style={titleWrap}>
          <div>
            <H1>メイン機能</H1>
          </div>
          <Text color={Colors.Text.Dark}>
            楽しい簡単な習慣付けを実現するための機能です
          </Text>
        </Box>
        <Box style={feature}>
          <img src={FeatureImage} alt="" css={featureImage} />
          {FEATURES.map((feature, index) => (
            <div
              key={index}
              css={[
                featureItem,
                index === 1
                  ? {[mq['tablet']]: {gridRow: 2}}
                  : index === 2
                  ? {[mq['tablet']]: {gridRow: 1, gridColumn: 3}}
                  : {},
              ]}>
              <FeatureItem
                title={feature.title}
                body={feature.body}
                number={`0${index + 1}`}
              />
            </div>
          ))}
        </Box>
      </Section>
    </>
  );
};

export default Top;

const fv = css`
  display: flex;
  flex-direction: column;
  padding: 48px 0 38px;
  margin: 0 24px;
  ${mq['tablet']} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    max-width: 1200px;
    padding: 66px 0;
  }
  ${mq['small']} {
    margin: 0 auto;
    column-gap: 79px;
  }
`;

const inspection = css`
  font-weight: 600;
  ${setFontSize(24)};
  line-height: 150%;
  color: #5ecda7;
`;

const fvImage = css`
  width: 100%;
  ${mq['sp']} {
    width: clamp(320px, calc(20rem + ((1vw - 3.2px) * 20.0893)), 545px);
    min-height: 0vw;
    align-self: center;
  }
`;

const borderWrap = css`
  column-gap: 8px;
  align-items: center;
  margin: 48px 0 24px;
`;

const border = css`
  border: solid 2px #5ecda7;
  width: 80px;
`;

const fvText = css`
  color: ${Colors.Text.Dark};
  line-height: 138%;
  margin-top: 24px;
`;

const fvButton = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  padding: 12px 24px;
  margin-top: 48px;
  background: #5ecda7;
  border: #5ecda7;
  border-radius: 32px;
  cursor: pointer;
  text-decoration: none;
  ${mq['tablet']} {
    padding: 16px 16px 16px 32px;
    column-gap: 8px;
  }
`;

const buttonText = css`
  font-family: 'Hiragino Kaku Gothic Pro';
  font-style: normal;
  font-weight: 600;
  ${setFontSize(16)};
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  ${mq['tablet']} {
    ${setFontSize(20)};
  }
`;

const background = css`
  background-color: #f2f3f7;
`;

const titleWrap = css`
  text-align: center;
  row-gap: 24px;
`;

const feature = css`
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  ${mq['tablet']} {
    display: grid;
    column-gap: 40px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const featureImage = css`
  width: min(max(280px, calc(17.5rem + ((1vw - 2.8px) * 15.3846))), 320px);
  margin-bottom: 24px;
  ${mq['tablet']} {
    width: min(max(240px, calc(15rem + ((1vw - 5.4px) * 16.6667))), 390px);
    grid-row: 1 / 3;
    grid-column: 2 / 3;
    margin: 0;
  }
`;

const featureItem = css`
  margin-bottom: 40px;
  width: 100%;
  ${mq['tablet']} {
    margin: 0;
  }
`;
