import {FC} from 'react';
import {css} from '@emotion/react';
import {setFontSize, Colors, mq} from '@/assets/styles';
import {Text, H2} from '@/components/atoms/text';
import {Box} from '@/components/atoms/layout';

type Props = {
  title: string;
  body: string;
  number: string;
};

const FeatureItem: FC<Props> = ({title, body, number}) => {
  return (
    <Box style={container}>
      <Box style={numberWrap}>
        <span css={numberText}>{number}</span>
      </Box>
      <Box style={textWrap}>
        <H2 style={featureTitle}>{title}</H2>
        <Text color={Colors.Text.Dark}>{body}</Text>
      </Box>
    </Box>
  );
};

export default FeatureItem;

const container = css`
  width: 100%;
  row-gap: 16px;
  ${mq['sp']} {
    row-gap: 24px;
  }
`;

const numberWrap = css`
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50px;
  width: 64px;
  height: 64px;
  ${mq['sp']} {
    width: 80px;
    height: 80px;
  }
`;

const numberText = css`
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  ${setFontSize(24)};
  line-height: 136%;
  background: linear-gradient(315deg, #60cddd 0%, #6bd5cf 36.04%, #9befa4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  ${mq['sp']} {
    ${setFontSize(32)};
  }
`;

const textWrap = css`
  row-gap: 16px;
  ${mq['sp']} {
    row-gap: 24px;
  }
`;

const featureTitle = css`
  ${setFontSize(24)};
  ${mq['sp']} {
    ${setFontSize(36)};
  }
`;
